import styled from "@emotion/styled";
export const StyledSideBySideWithBulletsTextAndAssetComponent = styled.div`
  display: flex;
  .side-by-side-asset-wrapper {
    flex-grow: 1;
    .side-by-side-text-and-asset-component {
      height: 100%;
    }
  }
`;
